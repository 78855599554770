var timeout;

window.addEventListener(
	"scroll",
	function(event) {
		// If there's a timer, cancel it
		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		// Setup the new requestAnimationFrame()
		timeout = window.requestAnimationFrame(function() {
            if (window.scrollY > 100) {
                document.querySelector('header.header').classList.add('fixed');
            }
            if (window.scrollY < 100) {
                document.querySelector('header.header').classList.remove('fixed');
            }
		});
	},
	false
);