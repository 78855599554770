function openNav() {
	document.querySelector("body").classList.toggle("menu-open");
}
function toggleSubMenu(offset) {
	document.querySelector("body").classList.toggle("open-submenu-" + offset);
}
function init() {
	/**
	 *
	 * HAMBURGER
	 *
	 ***********************/
	let menuToggle = document.querySelectorAll(".menu-toggle");
	[].forEach.call(menuToggle, (toggle) => {
		toggle.addEventListener("click", (e) => {
			e.stopPropagation();
			// use timeout to avoid misclick
			setTimeout(() => {
				openNav();
			}, 100);
		});
	});
	/**
	 *
	 * MENU ITEM ARROW
	 *
	 ***********************/
	const menuItemArrows = document.querySelectorAll(".menu-item-link");
	for (const menuItemArrow of menuItemArrows) {
		let arrow = menuItemArrow.querySelector(".menu-item-arrow");
		if (arrow) {
			arrow.addEventListener("touchstart", (e) => {
				setTimeout(() => {
					menuItemArrow.parentElement.classList.add("focus");
					toggleSubMenu(menuItemArrow.dataset.level);
				}, 100);
			});
		}
	}
	const goBack = document.querySelectorAll("ul .go-back");
	for (const btnGoBack of goBack) {
		btnGoBack.addEventListener("touchstart", (e) => {
			setTimeout(() => {
				toggleSubMenu(
					document.querySelectorAll(".menu-item.focus").length
				);
				btnGoBack.parentElement.closest("li").classList.remove("focus");
			}, 100);
		});
	}
}
document.addEventListener("DOMContentLoaded", init());
