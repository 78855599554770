document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
	anchor.addEventListener("click", function (e) {
		e.preventDefault();
		const target = e.target.getAttribute("href");
		const distance =
			document.querySelector("header").scrollHeight +
			document.querySelector(`${target}`).offsetTop;
		window.scrollTo({
			top: distance,
			left: 0,
			behavior: "smooth",
		});
	});
});
