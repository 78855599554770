function moveNavResponsive() {
	const nav = document.querySelector("#nav-main"),
		navWrapper = document.querySelector("#nav-wrapper"),
		navMobileWrapper = document.querySelector("#outside-content");

	var windowWidth = window.innerWidth;
	if (windowWidth <= returnHideMobile()) {
		!navMobileWrapper.contains(nav) && navMobileWrapper.append(nav);
	} else {
		!navWrapper.contains(nav) && navWrapper.append(nav);
	}
}

const returnHideMobile = () => {
	var hideMobile = window
		.getComputedStyle(document.documentElement)
		.getPropertyValue("--mobile-menu-hide");
	hideMobile = hideMobile.replace("px", "");
	return parseInt(hideMobile);
};

document.addEventListener("DOMContentLoaded", () => {
	moveNavResponsive();
});
var timeout;
window.addEventListener(
	"resize",
	function () {
		// If there's a timer, cancel it
		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		// Setup the new requestAnimationFrame()
		timeout = window.requestAnimationFrame(function () {
			moveNavResponsive();
		});
	},
	false
);
//esize(moveNavResponsive());
