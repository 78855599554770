//import "../../scss/composants/_accordeon.scss";

var accordeons = document.querySelectorAll(".accordeon");
if (accordeons.length > 0) {
	accordeons.forEach((accordeon) => {
		var opener = accordeon.querySelectorAll(".opener");
		opener.forEach((op) => {
			op.addEventListener("click", () => {
				var content = op.nextElementSibling;
				content.classList.toggle("open");
				op.querySelector(".arrow").classList.toggle("less");
				if (content.classList.contains("open")) {
					content.style.height = content.scrollHeight + "px";
				} else {
					content.style.height = 0;
				}
			});
		});
	});
}
