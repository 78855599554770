for (const [key, value] of Object.entries(
	document.querySelectorAll(".img-on-bg")
)) {
	var col = value.closest(".wpb_column");
	if (col.classList.contains("col-12")) {
		col.before(value);
	} else if (col.classList.contains("col-6")) {
		console.log(col);
		col.before(value);
		value.style.width = "50%";
		if (value.previousElementSibling) {
			value.style.right = 0;
			value.style.left = "auto";
		} else {
			value.style.left = 0;
		}
	}
}
