function popitContact() {
	const div = document.querySelectorAll(".wpcf7-form");
	div.length > 0 &&
		Array.from(div).map((child) => {
			const popitAcceptance = child.querySelector(".popit.acceptance");
			child
				.querySelector(".form_submit")
				.addEventListener("mouseenter", () => {
					let btnSubmit = child.querySelector(
						".wpcf7-form-control.wpcf7-submit"
					);
					if (btnSubmit.disabled == true) {
						popitAcceptance.style.opacity = 1;
						popitAcceptance.style.visibility = "visible";
					}
				});

			child
				.querySelector(".form_submit")
				.addEventListener("mouseleave", () => {
					popitAcceptance.style = "";
				});
		});
}

document.addEventListener("DOMContentLoaded", popitContact());
