for (const [key, value] of Object.entries(
	document.querySelectorAll(".img-on-bg")
)) {
    var col = value.closest(".wpb_column");
    console.log(value);
    if (col) {
        if (col.classList.contains("col-12")) {
            col.after(value);
        } else if (col.classList.contains("col-6")) {
            col.after(value);
            if (value.previousElementSibling.previousElementSibling) {
                value.style.right = 0;
                value.style.left = "auto";
            } else {
                value.style.left = 0;
            }
        } else {
        }
    }
}
